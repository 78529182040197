import { graphql } from "gatsby"
import parse from "html-react-parser"
import React from "react"
import FeaturedImage from "../components/FeaturedImage"
import Layout from "../layouts/home"

class Index extends React.Component {
  render() {
    let {
      location,
      data: {
        wpPage: {
          title,
          excerpt,
          content,
          featuredImage,
          acf_page_content: { lead },
          acf_cards: { cards },
          acf_postImage: { postImage },
          seo,
        },
      },
    } = this.props

    if (featuredImage && postImage) {
      featuredImage.node = postImage
    }

    // console.log(featuredImage)

    // Use default og:image
    // seo.opengraphImage.localFile.publicURL = `${process.env.GATSBY_SITE_URL}/images/social/digitalexactly.jpg`;

    return (
      <Layout
        location={location}
        featuredImage={featuredImage?.node}
        cssClass="home"
        title={title}
        description={excerpt}
        seo={seo}
      >
        <section id="content">
          <div className="row">
            <div className="col-sm-8 col-lg-11 col-xl-10">
              <p className="lead">{lead}</p>
              {parse(content)}
            </div>
            <FeaturedImage
              image={featuredImage?.node}
              page="home"
              classes="mobile"
            />
          </div>
          <div className="row">
            {cards &&
              cards.map((item, index) => {
                // console.log(item.logo.imageFile.publicURL);
                return (
                  <div className="post col-sm-6 col-lg-6" key={index}>
                    <div className="cardwrapper">
                      <div className="card">
                        {item && item.icon && (
                          <div>
                            <img
                              src={item.icon.localFile.publicURL}
                              height="50"
                              alt={parse(item.title)}
                            ></img>
                          </div>
                        )}
                        <h2>{parse(item.title)}</h2>
                        {parse(item.copy)}
                      </div>
                    </div>
                  </div>
                )
              })}
          </div>
        </section>
      </Layout>
    )
  }
}

export default Index

export const pageQuery = graphql`
  {
    wpPage(isFrontPage: { eq: true }) {
      id
      title
      excerpt
      content
      featuredImage {
        node {
          ...featuredImage
        }
      }
      acf_postImage {
        postImage {
          ...featuredImage
        }
      }
      acf_page_content {
        lead
      }
      acf_cards {
        cards {
          title
          copy
          icon {
            altText
            sourceUrl
            srcSet
            id
            modified
            localFile {
              extension
              publicURL
            }
          }
        }
      }
      seo {
        ...WpYoastSEO
      }
    }
  }
`
